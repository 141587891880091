import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { navigate } from 'gatsby';
//Components
import Form from '../components/flyer/form';
import Footer from '../components/flyer/footer';
import Layout from '../components/layout';
import Slider from '../components/slider/slider';
//Material UI
import Dialog from '@material-ui/core/Dialog';

export default function Flyer({ pageContext }) {
  const [categorie, setCategorie] = useState(pageContext.buttons[0].value);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (isMobile) {
      navigate(`movil/${window.location.pathname}`);
    }
  });
  return (
    <Layout contact={pageContext.contact} brand={pageContext.brand} header>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <Form
          background={pageContext.brand.background.desktop}
          brand={pageContext.brand}
          contact={pageContext.contact}
          handleClose={() => setOpen(false)}
          logo={pageContext.brand.logos.form}
        />
      </Dialog>
      <Slider slides={pageContext.slides[categorie].desktop} />
      <Footer
        categories={pageContext.buttons}
        handleCategorie={setCategorie}
        handleMore={() => setOpen(true)}
        logo={pageContext.brand.logos.flier}
      />
    </Layout>
  );
}
