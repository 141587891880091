import React from 'react';
//Components
import Icon from '../global/icon';
//Img
import fb from '../../images/fb.svg';
import ig from '../../images/ig.svg';
import line from '../../images/line.svg';
import website from '../../images/website.svg';
//Material UI
import Grid from '@material-ui/core/Grid';
import location from '../../images/location.svg';
import whatsapp from '../../images/whatsapp.svg';
import mail from '../../images/mail.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root:{
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '11px',
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(0.5),
    textAlign: 'right',
    width: '100%'
  },
  icons: {
    alignContent: 'center',
    display: 'flex',
    marginRight: '5px'
  },
  line: {
    margin: '0 5px',
    height: '25px',
    width: '4px'
  },
  social: {
    height: '100%',
    width: '25px'
  }
}));

export default ({ contact }) => {
  const classes = useStyles();  
  return (
    <Grid alignItems="center" className={classes.root} container justify="space-between">
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <a 
              href={contact.location}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon Icon={location} />
            </a>
          </Grid>
          <Grid item>
            <a 
              href={`mailto:${contact.email}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon Icon={mail} />
            </a>
          </Grid>
          <Grid item>
            <a 
              href={`https://wa.me/${contact.whatsapp}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon Icon={whatsapp} />
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.icons} item>
        <a 
          href={contact.facebook}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img className={classes.social} src={fb} alt=""/>
        </a>
        <img className={classes.line} src={line} alt=""/>
        <a 
          href={contact.instagram}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img className={classes.social} src={ig} alt=""/>
        </a>
        <img className={classes.line} src={line} alt=""/>
        <a 
          href={contact.website}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img  className={classes.social} src={website} alt=""/>
        </a>
      </Grid>
    </Grid>
  );
};
