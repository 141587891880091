import React from 'react';
//Material UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
//Stles
import footerStyles from './footer.module.css';

export default ({categories, handleCategorie, handleMore, logo}) => (
  <Grid className={footerStyles.root} alignItems="flex-end" container justify="space-between">
    <Grid item>
      <Grid container spacing={1}>
        <Grid item>
          <Button color="primary" onClick={handleMore} variant="contained" >
            + INFORMACIÓN
          </Button>
        </Grid>
        {
          categories
          ?
          categories.map(({ label, value }, i) => (
            <Grid item key={i}>
              <Button color="secondary" onClick={() => handleCategorie(value)} variant="contained">
                {label}
              </Button>
            </Grid>
          ))
          : 
          []
        }
      </Grid>
    </Grid>
    <Grid item>
      <img className={footerStyles.logo} src={logo} alt="logo"/>
    </Grid>
  </Grid>
);
